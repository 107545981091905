$success_d: #009900;
$white: #fff;
$light_yellow: #fff9e3;
$light_gray: #a9abad;
$gray: #717171;
$dark: #292d32;
$red: #dd1414;
$light_red: #f7b3ba;
$brand: #ffd741;
$secondary_brand: #6b493e;
$brand_shadow: rgba(255, 215, 65, 0.4);
$red_shadow: rgba(221, 20, 20, 0.4);
$dark_shadow: rgba(41, 45, 50, 0.3);

$base: #263238;
$lite: #009900;
$brand1: #ff3d00;
$size: 30px;
