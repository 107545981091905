.hymn__container {
  display: flex;
  justify-content: center;
}
.ta_player_box {
  border-radius: 5px;
  padding: 10px;
  background-color: $brand;
  width: clamp(250px, 50vw, 500px);

  input[type='range'] {
    width: 100%;
    accent-color: $gray;
    margin-top: 10px;
  }
}
.player_controls_container {
  display: flex;
  justify-content: space-between;
}
.player_controls_row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: clamp(10px, 5vw, 30px);

  & div {
    cursor: pointer;
    transition: 0.5s;
    color: $dark;

    &:hover {
      opacity: 0.7;
    }
  }
}
.player_title_box {
  display: flex;
  align-items: center;
  gap: 5px;
}
.player_title_icon {
  color: $secondary_brand;
  opacity: 0.4;
}
.player_title_text {
  color: $secondary_brand;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
.player_current_time_row {
  display: flex;
  align-items: center;
  justify-content: end;
}
.player_current_time {
  margin-left: auto;
  color: $gray;
}
.player_progress_container {
  margin-top: 12px;

  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: white;

  .player_progress {
    width: 0%;
    height: 10px;
    border-radius: 5px;
    background-color: $dark;
  }
}
// media 697
@media (max-width: 697px) {
  .player_controls_container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
} //max-width:697px
