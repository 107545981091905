$title_size: 26px;
$title_size_740: 22px;
$title_size_470: 20px;

.cmb {
  margin-bottom: 35px;
}
.cmb45 {
  margin-bottom: 45px;
}

.custom_wrap {
  flex: 1 0 auto;
  width: 100%;
}
.custom_container {
  padding: 20px 0 50px 0;
  margin: 20px 0;
  & h2,
  h1 {
    font-size: $title_size;
    text-align: center;
  }
}
.custom_container.taxi_automation {
  padding-bottom: 0;
}

.custom_color_container {
  background-color: $light_yellow;
  padding: 20px 0 50px 0;
  margin: 20px 0;
  & h1 {
    font-size: $title_size;
    text-align: center;
  }
  & ul {
    list-style: none; // Убираем стандартные маркеры списка
    padding-left: 0; // Убираем отступ слева
    font-size: 18px;
    li {
      position: relative; // Для корректного позиционирования псевдоэлемента
      padding-left: 30px; // Отступ слева для размещения галочки
      margin-bottom: 8px;
      &::before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 14px;
        position: absolute;
        left: 0; // Позиционирование галочки слева
        top: 50%; // Центрируем по вертикали
        transform: translateY(-50%); // Центрируем по вертикали
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none"><path d="M17.1328 1.11729C17.6211 1.60557 17.6211 2.39463 17.1328 2.88291L7.13281 12.8829C6.64453 13.3712 5.85547 13.3712 5.36719 12.8829L0.366094 7.88291C-0.122031 7.39463 -0.122031 6.60557 0.366094 6.11729C0.854297 5.629 1.6457 5.629 2.13398 6.11729L6.21484 10.2306L15.3672 1.11729C15.8555 0.628223 16.6445 0.628223 17.1328 1.11729Z" fill="%2327C840"/></svg>');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
}
.main__btn_a {
  display: block;
  width: 305px;
  margin: 0 auto;
}
.custom_img_block {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 20px;
}
.img_block_text {
  grid-column: 1;
  font-size: 16px;
}
.img_block_img {
  position: relative;
  grid-column: 2;
  width: 700px;
  height: 500px;
  & img {
    width: 100%;
  }
  & .img_text {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 22px;

    width: 400px;
    text-align: right;
    padding-top: 20px;
    padding-right: 30px;
  }
}
.support_stages_wrap {
  margin-bottom: 30px;
}
// support_stages
.support_stages_container {
  margin-bottom: 30px;
  & h2 {
    font-size: $title_size;
    text-align: center;
  }
}
.support_stages_wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
  margin-top: 30px;
  justify-content: center;
}
.support_stages_block {
  display: flex;
  align-items: flex-start;
  width: 265px;
  // flex: 1 1 20%;
  // flex: 0 1;
  gap: 5px;
  & p {
    margin: 2px 0;
  }
}

.support_stages_number {
  font-size: 8rem;
  line-height: 6rem;
  color: $brand;
}
.support_stages_title {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 7px;
}
.support_stages_description {
  font-size: 12px;
}
// business_calculator
.business_calculator_container {
  margin-bottom: 30px;
  & h2 {
    font-size: $title_size;
    text-align: center;
  }
}
.business_calculator_wrap {
  display: flex;
  justify-content: center;
}
.business_calculator_form {
  padding: 10px;
  background-color: $light_yellow;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 400px 1fr;
  align-items: center;
  gap: 10px 0;
}
.business_calculator_form_title {
  font-weight: 700;
  grid-column: span 2;
  border-bottom: 1px solid $light_gray;
  // border-top: 1px solid $light_gray;
  padding: 4px 0;
}
.business_calculator_form_label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
  & span {
    text-wrap: nowrap;
  }
  & hr {
    width: 100%;
    border-top: 2px dotted $gray;
  }
}
.business_calculator_hr {
  width: 100%;
  padding: 0 10px;
}
.business_calculator_form_input {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  & .business_calculator_form_input_text {
    font-weight: 700;
  }
  & input {
    transition: 300ms ease-in-out;
    height: 28px;
    border-bottom: 2px solid $light_gray;
    // background-color: $light_yellow;
    opacity: 0.7;
    outline: none;

    &:focus {
      border-color: $dark;
    }
  }
  & select {
    transition: 300ms ease-in-out;
    border: 2px solid $light_gray;
    border-radius: 5px;
    height: 28px;
    opacity: 0.7;
    outline: none;
    &:focus {
      border-color: $dark;
    }
  }
  & input[type='checkbox'] {
    width: 15px;
  }
}

.business_calculator_form_total_text {
  text-align: right;
  font-weight: 700;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  background-color: $brand;
  opacity: 0.7;
  border-radius: 5px 0 0 5px;
  margin-top: 20px;
}
.business_calculator_form_total {
  // text-align: right;
  margin-top: 20px;
  font-weight: 700;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: $brand;
  opacity: 0.7;
  border-radius: 0 5px 5px 0;
}

.business_calculator_disclaimer {
  max-width: 650px;
  grid-column: span 2;
  font-size: 12px;
  color: $light_gray;
}
// problems_container
.problems_container {
  position: relative;
  background-color: $light_red;
  padding: 20px 0 50px 0;
  margin-bottom: 20px;
  margin-top: -40px;
  overflow: hidden;

  & h2 {
    position: relative;
    z-index: 22;
  }
  & ul {
    position: relative;
    z-index: 22;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    li {
      position: relative; // Для корректного позиционирования псевдоэлемента

      // width: 20%;
      width: 400px;
      font-size: 16px;
      border-radius: 5px;
      padding: 10px 10px 10px 40px;
      // background-color: rgba(255, 255, 255, 0.3);

      &::before {
        content: '';
        display: inline-block;
        width: 32px;
        height: 32px;
        position: absolute;
        left: 0; // Позиционирование значка слева
        top: 15px; // Центрируем по вертикали
        //transform: translateY(-50%); // Центрируем по вертикали
        background-image: url('data:image/svg+xml;utf8,<svg width="24" height="20" viewBox="0 0 24 22"  xmlns="http://www.w3.org/2000/svg"><path d="M20.7605 13.92L14.3605 2.4C13.5005 0.85 12.3105 0 11.0005 0C9.69047 0 8.50047 0.85 7.64047 2.4L1.24047 13.92C0.430466 15.39 0.340466 16.8 0.990466 17.91C1.64047 19.02 2.92047 19.63 4.60047 19.63H17.4005C19.0805 19.63 20.3605 19.02 21.0105 17.91C21.6605 16.8 21.5705 15.38 20.7605 13.92ZM10.2505 7C10.2505 6.59 10.5905 6.25 11.0005 6.25C11.4105 6.25 11.7505 6.59 11.7505 7V12C11.7505 12.41 11.4105 12.75 11.0005 12.75C10.5905 12.75 10.2505 12.41 10.2505 12V7ZM11.7105 15.71C11.6605 15.75 11.6105 15.79 11.5605 15.83C11.5005 15.87 11.4405 15.9 11.3805 15.92C11.3205 15.95 11.2605 15.97 11.1905 15.98C11.1305 15.99 11.0605 16 11.0005 16C10.9405 16 10.8705 15.99 10.8005 15.98C10.7405 15.97 10.6805 15.95 10.6205 15.92C10.5605 15.9 10.5005 15.87 10.4405 15.83C10.3905 15.79 10.3405 15.75 10.2905 15.71C10.1105 15.52 10.0005 15.26 10.0005 15C10.0005 14.74 10.1105 14.48 10.2905 14.29C10.3405 14.25 10.3905 14.21 10.4405 14.17C10.5005 14.13 10.5605 14.1 10.6205 14.08C10.6805 14.05 10.7405 14.03 10.8005 14.02C10.9305 13.99 11.0705 13.99 11.1905 14.02C11.2605 14.03 11.3205 14.05 11.3805 14.08C11.4405 14.1 11.5005 14.13 11.5605 14.17C11.6105 14.21 11.6605 14.25 11.7105 14.29C11.8905 14.48 12.0005 14.74 12.0005 15C12.0005 15.26 11.8905 15.52 11.7105 15.71Z" fill="%23dd1414"/></svg>');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  &.problems_container_column ul {
    flex-direction: column;
    gap: 14px;
    & li {
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
      & p {
        margin-bottom: 0;
        margin-top: 8px;
      }
    }
  }

  &.column_3 ul {
    & li {
      width: 30%;
    }
  }
}

.problems_img {
  display: flex;
  justify-content: center;
  width: clamp(500px, 200%, 1350px);
  // width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 10px 0;
}
.problems_chart {
  width: 45%;
  opacity: 0.6;
}
.working_walkies_container {
  margin-top: -20px;
  // display: flex;
  // gap: 20px;
  padding-top: 20px;
  // align-items: center;
  & .main__btn_a {
    min-width: 303px;
  }
  & button {
    margin: 0;
  }
}
.btn_pdf {
  margin-top: 20px;
  & .main__btn_a {
    width: 370px;
  }
}

// media
@media (max-width: 1364px) {
  .custom_color_container ul {
    font-size: 16px;
  }
  .problems_container ul {
    font-size: 16px;
  }
} //@media (max-width: 1364px)

@media (max-width: 1270px) {
  .img_block_text {
    grid-column: span 2;
    grid-row: 2;
  }
  .img_block_img {
    grid-column: span 2;
    grid-row: 1;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
  }
} //@media (max-width: 1270px)
@media (max-width: 1024px) {
  .problems_container {
    &.column_3 ul {
      & li {
        width: 45%;
      }
    }
  }
} //@media (max-width: 1024px)

@media (max-width: 740px) {
  .custom_color_container,
  .support_stages_container,
  .business_calculator_container,
  .working_walkies_block,
  .problems_container,
  .custom_container {
    & h2,
    h1 {
      font-size: $title_size_740;
    }
  }
  .problems_container {
    &.column_3 ul {
      & li {
        width: 95%;
      }
    }
  }
  .img_block_img {
    padding: 0 20px;
    width: 100%;

    & .img_text {
      font-size: clamp(0.5rem, 3vw, 1.375rem);
      gap: 8px;
      right: 20px;
      width: 57%;
    }
  }
  // калькулятор 740
  .business_calculator_container {
    padding: 0 20px;
  }

  .business_calculator_form {
    font-size: 12px;
    grid-template-columns: minmax(90px, 250px) 1fr;
    & .business_calculator_hr {
      display: none;
    }
  }
  .business_calculator_form_label span {
    width: 100%;
    text-wrap: wrap;
  }
  .business_calculator_form_input select {
    font-size: 10px;
    max-width: 190px;
  }
  .business_calculator_form_input input {
    font-size: 12px;
  }
  .custom_color_container ul {
    font-size: 14px;
  }

  .business_calculator_form {
    font-size: 10px;
  }

  .problems_img {
    display: none;
  }

  .problems_container ul {
    gap: 0;
    li {
      font-size: 14px;
    }
  }
} //@media (max-width: 740px)

@media (max-width: 584px) {
  .img_block_img {
    & .img_text {
      padding-top: 16px;
      padding-right: 25px;
      right: 10px;
    }
  }
  .support_stages_block {
    width: 100%;
  }
  .business_calculator_form_input {
    font-size: 10px;
    input {
      width: clamp(50px, 50%, 150px);
    }
  }

  .working_walkies_container .main__btn_a {
    // width: 303px;
    margin-left: auto;
    margin-right: auto;
  }
  .problems_container h2 {
    padding: 0 10px;
  }
} //@media (max-width: 584)

@media (max-width: 470px) {
  .custom_color_container,
  .support_stages_container,
  .business_calculator_container,
  .working_walkies_block,
  .problems_container,
  .custom_container {
    & h2,
    h1 {
      font-size: $title_size_470;
    }
  }

  .img_block_img {
    & .img_text {
      padding-top: 6px;
      right: 10px;
      width: 53%;
    }
  }
  // калькулятор 470

  .business_calculator_form_input {
    text-align: right;
    & select {
      max-width: 150px;
    }
  }

  .problems_container ul {
    padding-left: 20px;
  }
} //@media (max-width: 470px)

@media (max-width: 343px) {
  .img_block_img {
    & .img_text {
      padding-top: 4px;
      padding-right: 17px;
      width: 55%;
    }
  }
} //@media (max-width: 343px)
