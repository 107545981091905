body {
  font-family: 'Open Sans', sans-serif;
  background-color: $white;
  font-size: 14px;
  color: $dark;
}
.page {
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  justify-content: center;
  // align-items: center;
}
//формы
.form__title {
  font-size: 22px;
  font-weight: 700;
}
.form__row {
  padding: 15px;
}
.form__input {
  padding: 3px 10px;
  background: #ffffff;
  font-size: 14px;
  border-radius: 5px;
  border: 2px solid;
  border-color: rgba(253, 181, 24, 1);
  width: 320px;
  height: 48px;
  &:focus {
    outline: 0;
    border-color: rgba(253, 181, 24, 1);
    box-shadow: 0 0 6px 3px rgba(253, 180, 24, 0.3);
  }
}
.form__input:-webkit-autofill {
  & .lable {
    transition: 0.5s;
    position: absolute;
    z-index: 7;
    left: 10px;
    top: 14px;
    pointer-events: none;
  }
}
.login__form {
  padding: 30px;
  background-color: $light_yellow;
  border-radius: 8px;
  box-shadow: 0px 15px 22px rgba(41, 45, 50, 0.4);
}
.input__box {
  position: relative;
}
.input__lable {
  position: absolute;
  left: 0;
  font-size: 16px;
  margin: 11px 15px;
  pointer-events: none;
  color: $light_gray;
  transition: 0.5s;
}
.input__box input:valid ~ span,
.input__box input:focus ~ span {
  font-size: 13px;
  border-radius: 4px;
  padding: 2px 6px;
  color: $white;
  transform: translateX(10px) translateY(-23px);
  background-color: rgba(253, 181, 24, 1);
  box-shadow: 0px -3px 6px rgba(253, 180, 24, 0.3);
}
.error__text {
  background-color: $red;
  padding: 10px;
  margin: 0px 15px;
  border-radius: 8px;
  color: $white;
}
.form__input__error {
  border-color: $red;
  box-shadow: 0 0 6px 3px rgba(221, 20, 20, 0.3);
}
//кнопки
.btn__wrapp {
  position: relative;
  margin-bottom: 45px;
}
button {
  cursor: pointer;
  padding: 10px 25px;
  color: $dark;
  font-size: 14px;
}

.btn__login {
  position: absolute;
  padding: 12px 27px;
  left: 0;
  top: 0;
  font-size: 16px;
  background-color: $light_yellow;
  border: 2px solid;
  border-color: rgba(253, 181, 24, 1);
  border-radius: 8px;
  transition: 0.5s;
  &:hover {
    font-size: 16px;
    letter-spacing: 1px;
    box-shadow: 0 0 20px 8px rgba(253, 181, 24, 0.5);
  }
}
.page__wrapp {
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
.rectangle__fone {
  position: absolute;
  z-index: 0;
  width: 2955px;
  height: 1065px;
  background-color: $light_yellow;
  left: -500px;
  top: 350px;
  transform: rotate(-23deg);
}
.content__container {
  position: relative;
  margin-top: 10px;
  max-width: 1360px;
  padding: 0 25px;
  margin-left: auto;
  margin-right: auto;
}
.content__container.rel {
  margin-bottom: 0;
}
.nav__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
}
.nav__fix__box {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: $white;
  z-index: 99;
}
.nav__container__fix {
  /*    position: fixed;
    left: 0; */
  /*   left: 50%;
    transform: translateX(-50%);
    ; */
  transition: 0.5s;
  /*     background-color: $white; */
  box-shadow: 0px 4px 8px rgba(41, 45, 50, 0.1);
  top: 0;
  //z-index: 100;
  /*  border-bottom: 2px solid $brand; */
  /*    animation: show_nav;
    animation-duration: .5s; */
}
@keyframes show_nav {
  0% {
    /* opacity: 0;
        top: -100px; */
  }
  100% {
    /* opacity: 1;
        top: 0; */
    box-shadow: 0px 4px 8px rgba(41, 45, 50, 0.1);
  }
}
.lang__name {
  margin-left: 10px;
  white-space: nowrap;
}
.logo img {
  width: 329px;
}

nav {
  padding: 5px 0;
}
.nav__link {
  padding: 5px;
  margin-right: 25px;
  color: $gray;
  transition: 0.5s;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: $dark;
  }
}
.phone {
  display: flex;
  align-items: center;
  padding: 5px 0;
  & a {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    margin-left: 7px;
  }
  & svg {
    margin-left: 10px;
    margin-right: 7px;
  }
  & span {
    padding-left: 5px;
    text-shadow: 0 0 10px 8px $brand_shadow;
  }
}

.custom_link_wrap {
  margin-top: 6px;
  display: flex;
  gap: 18px;
  justify-content: center;
  flex-basis: 100%;
  margin-bottom: 16px;
}
.custom_link {
  transition: 0.5s;
  color: $secondary_brand;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  border: 1px solid $secondary_brand;
  border-radius: 5px;
  &:hover {
    opacity: 0.7;
  }
}
.burger_custom_link_wrap {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-left: 26px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 26px;
  & .custom_link {
    padding: 0;
    border: none;
  }
}
.burger_hr {
  padding: 0 20px;

  & hr {
    border: 1px solid $gray;
    opacity: 0.2;
  }
}
button {
  background-color: $white;
  font-size: 16px;
  transition: 0.4s;
}
.secondary__btn {
  border: 2px $brand solid;
  border-radius: 5px;
  padding: 8px 15px;
  margin-right: 21px;
  &:hover {
    box-shadow: 0 0 10px 8px $brand_shadow;
  }
}
.primary__btn {
  background-color: $brand;
  border-radius: 5px;
  padding: 9px 21px;
  &:hover {
    box-shadow: 0 0 10px 8px $brand_shadow;
    color: $white;
  }
}
.dropdown__btn {
  background-color: transparent;
  padding: 10px 0;
  display: flex;
  align-items: center;
  & .svg__flag {
    margin-right: 5px;
  }
  & .svg__arrow {
    transition: 0.4s;
    margin-left: 3px;
  }
}
.dropdown__btn.main {
  position: relative;
  z-index: 99;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    left: -100%;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: $brand;
    transition: 0.4s;
  }
}

.nav__btn__container {
  & button {
    margin-right: 19px;
  }
}

.dropdown__wrapp {
  position: relative;
  &:hover .dropdown__btn.main {
    &::before {
      left: 0;
    }
  }
}
.dropdown__menu {
  background-color: rgba(41, 45, 50, 0.4);
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  border-radius: 8px;
  display: none;
  & button {
    padding: 7px 0 7px 10px;
    //border-left: 2px $brand solid;
    background-color: transparent;
  }
  & .dropdown__btn:hover {
    transform: translateX(10px);
    color: $brand;
  }
}

.dropdown__wrapp.active {
  & .dropdown__menu {
    display: block;
    transform: translateY(46px);
  }
  & .svg__arrow {
    transform: rotate(180deg);
  }
  & .dropdown__btn.main::before {
    left: 0;
  }
}
.main__section__container {
  margin-top: 20px;
  display: flex;
  //justify-content: space-between;
  align-items: center;
}
.text__wrapp {
  max-width: 520px;
  min-width: 480px;
  margin-right: 20px;
  & h1 {
    font-size: 22px;
    font-weight: 600;
    margin: 0 0 20px 0;
  }
  .text {
    font-size: 16px;
    margin-bottom: 20px;
    width: 90%;
  }
}
.main__btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-color: $brand;
  border-radius: 5px;
  padding: 15px 45px;
  &:hover {
    box-shadow: 0 0 11px 10px $brand_shadow;
    color: $white;
  }
}
.second__text {
  font-size: 14px;
}
.accent__text {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
//-------
.main__video__wrapp {
  position: relative;
  // width: 40.1vw;
  min-height: 440px;
  display: flex;
  //background-color: tomato;
  text-align: center;
  justify-content: space-between;
  width: 100%;
  //margin-right: 50px;
  & div {
    transition: 0.5s;
  }
  /*     & div:hover{
        z-index: 4;
        transform: scale(1.1);
        background-color: #b8b6b6;;
    } */
}
.clients__app {
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 80px;

  background-color: #cfcccc;
  box-shadow: 0px 10px 30px rgba(41, 45, 50, 0.2);
  border-radius: 8px;
  overflow: hidden;
  & video {
    transition: 0.5s;
    width: 11vw;
    height: auto;
  }
  &:hover {
    z-index: 4;
    & video {
      //width: 210px;
      width: 225px;
      height: auto;
    }
  }
}
.dispetcherskaya {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 200px;
  background-color: #cfcccc;
  box-shadow: 0px 10px 30px rgba(41, 45, 50, 0.2);
  border-radius: 8px;
  overflow: hidden;
  & video {
    transition: 0.5s;
    width: 38vw;
    height: auto;
  }
  &:hover {
    z-index: 4;
    left: 150px;
    & video {
      //transform: scale(2);
      width: 770px;
      left: 150px;
    }
  }
}
.driver__app {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  /* height: 337px;
    width: 153px; */
  background-color: #cfcccc;
  box-shadow: 0px 10px 30px rgba(41, 45, 50, 0.2);
  border-radius: 8px;
  overflow: hidden;
  & video {
    transition: 0.5s;
    height: auto;
    width: 10vw;
  }
  &:hover {
    z-index: 4;
    & video {
      height: auto;
      width: 225px;
    }
  }
}
//robot__wrapp
.robot__wrapp {
  margin-top: 30px;
  display: flex;
  text-align: center;
  flex-direction: column;
}
.section__header {
  font-size: 26;
  font-weight: 700;
}
.robot__info {
  font-size: 24;
}
.robot__video {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  &::after {
    content: ' ';
    width: 10px;
    height: 100%;
    position: absolute;
    z-index: 97;
    background-color: $light_yellow;
    left: 0;
    top: 0;
  }
  &::before {
    content: ' ';
    width: 10px;
    height: 100%;
    position: absolute;
    z-index: 97;
    background-color: $light_yellow;
    right: 0;
    top: 0;
  }
}

#robot {
  position: relative;
  //max-width: 1310px;
  max-width: 100%;
}
.play__circle__box {
  width: 100%;
  height: 100%;
}
.video__play {
  position: absolute;
  z-index: 90;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  background-color: $brand;
  border-radius: 100%;
  box-shadow: 0px 20px 50px rgba(41, 45, 50, 0.4);
  transition: 1s;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 35px 60px rgba(255, 215, 65, 0.8);
    & .play__circle__box {
      animation: rotate 2s linear infinite;
    }
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.play__icon {
  width: 100%;
  height: 100%;
  position: relative;
}
.video__play__circle {
  position: absolute;
  z-index: 91;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
}
.video__play__play {
  position: absolute;
  z-index: 92;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.control__box {
  width: 100%;
  background-color: $brand;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 10px;
  & svg {
    transition: 0.5s;
    fill: $dark;
  }
  & div {
    padding: 5px;
    margin: 0 15px;
    cursor: pointer;
    &:hover {
      & svg {
        transform: scale(1.4);
      }
    }
  }
}
.section__container {
  margin-top: 50px;

  & .section__header {
    text-align: center;
  }
  & ul {
    margin-left: 95px;
  }
}
.section__box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.section__text {
  margin-right: 15px;
}
//slider
.slider {
  & ul {
    margin-left: 0;
  }
}
.slide__text {
  margin-right: 15px;
  max-width: 650px;
}
.slide__control {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.dots__box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dots {
  background-color: $brand;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0 7px;
  cursor: pointer;
}
.dots.active {
  width: 25px;
  height: 25px;
  background-color: $secondary_brand;
}
.right_arrow,
.left__arrow {
  cursor: pointer;
}
.right_arrow {
  margin-left: 15px;
}
.left__arrow {
  margin-right: 15px;
}
.slide__img {
  & img {
    width: 100%;
  }
}
.slide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  position: relative;
}
.color__container {
  background-color: $light_yellow;
  padding: 20px 0 50px 0;
  margin: 20px 0;
  & h2 {
    margin: 0;
    padding: 0 0 20px 0;
  }
}
.form__box {
  display: flex;
  align-items: flex-start;
  & .form__input {
    height: 40px;
    border-color: $brand;
  }
  & .form__row {
    position: relative;
    padding: 5px 0;
  }
  & .lable {
    transition: 0.5s;
    position: absolute;
    z-index: 7;
    left: 10px;
    top: 14px;
    pointer-events: none;
  }
  & .form__column {
    margin-right: 10px;
  }
  &.form__row.flex {
    display: flex;
    text-align: center;
    justify-content: flex-end;
  }
  & .secondary__btn {
    margin-left: auto;
    margin-right: 0;
    background-color: $light_yellow;
  }
}
.form__box__col {
  flex-direction: column;
  margin: 30px auto;
  padding: 25px;
  background-color: $light_yellow;
  border-radius: 10px;
  width: max-content;
  & .form__row {
    margin: 8px 0;
  }
}
.form__input__textarea {
  height: 91px !important;
  //position: relative;
  z-index: 11;
}
.form__box__color {
  padding: 15px;
  height: min-content;
  position: relative;
  &::after {
    content: ' ';
    position: absolute;
    z-index: -1;
    background-color: $light_yellow;
    //bottom: 0;
    top: 0;
    left: -25%;
    //transform: translateX(50%);
    // width: 100vw;
    width: 2500px;
    height: 138px;
  }
}
.lable.active {
  top: -3px;
  left: 15px;
  background-color: $brand;
  font-size: 12px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 5px;
  color: $white;
  z-index: 10;
}
.section__text {
  font-size: 16px;
}
.section__img {
  & img {
    max-width: 650px;
  }
}
.advantages__container {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
}
.advantages {
  display: flex;
  align-items: center;
  width: 430px;
  margin-top: 50px;
  /*  margin-right: 10px; */
}
.advantages__header {
  font-size: 16px;
  font-weight: 600;
}
.advantages__info {
  margin-left: 12px;
}
.text__row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.section__box.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0;
  & .section__text {
    margin-right: 40px;
    font-weight: 600;
    &:last-child {
      margin-right: 0;
    }
  }
}
.text__icons {
  margin-right: 10px;
}
.support__box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 50px;
}
.support__text__box {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  & ul {
    margin-left: 0;
  }
}
.support__info {
  text-align: center;
}
.suppor__icons {
  margin-right: 40px;
}
.price__container {
  display: flex;
  align-items: center;
}
.price__header {
  font-size: 26px;
  font-weight: 600;
}
.price__info {
  font-size: 20px;
}
.box__price {
  max-width: 50%;
  & .content__link {
    display: inline-block;
    margin-top: 10px;
  }
}
.box__price.rel {
  position: relative;
  z-index: 2;
  & span {
    font-size: 16px;
    font-weight: 600;
  }

  & button {
    margin-left: 0;
    margin-top: 15px;
  }
}
.color__container.rel {
  position: relative;
  margin-bottom: 0;
  & svg {
    z-index: 1;
    position: absolute;
    top: 50%;
    right: 20%;
    width: 298px;
    //height="245"
    height: auto;
    transform: translateY(-50%);
  }
}
.info__span {
  position: relative;
  z-index: 3;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 30px;
}
.news__box {
  display: flex;
  align-items: baseline;
}
.news {
  padding: 15px;
  border-radius: 10px;
  background-color: $light_yellow;
  border: 2px $brand solid;
  margin-right: 10px;
  min-width: 33%;
  min-height: 270px;
  &:last-child {
    margin-right: 0;
  }
  & div {
    margin-bottom: 5px;
  }
  & .secondary__btn {
    background-color: $light_yellow;
  }
  & a {
    margin-top: auto;
  }
}
.news.m__10 {
  height: auto;
}
.news__header {
  font-size: 16px;
  font-weight: 600;
  margin-top: 5px !important;
  & a {
    transition: 0.5s;
    &:hover {
      color: $brand;
      text-decoration: none;
    }
  }
}
.news__date {
  font-size: 12px;
  color: $success_d;
  margin-bottom: 0 !important;
}
.news__text {
  font-size: 12px;
}

.btn__box {
  text-align: center;
}
.test__text {
  font-size: 16px;
  max-width: 80%;
  text-align: center;
  margin: 0 auto 15px auto;
}
.secondary__tel {
  font-weight: 600;
  font-size: 22px;
}
.flex__row__tel {
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    margin-right: 10px;
  }
}
footer {
  padding: 10px 0;
  background-color: $dark;
  color: $white;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  & a {
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      color: $brand;
    }
  }
  & .section__container {
    padding: 0;
  }
  .content__container {
    margin-top: 0;
  }
}
.content__link {
  color: $secondary_brand;
  text-decoration: underline;
}
/* .height_p{
    height: 100%;
} */
.page__container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.flex__nav {
  flex: 0 0 auto;
}
.content__wrapp {
  flex: 1 0 auto;
  width: 100%;
}
footer {
  flex: 0 0 auto;
}
.m__10 {
  margin: 15px 0;
}
.page__text {
  margin-right: 20px;
}
.text__wrapp__p {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
.back {
  margin-top: 15px;
  color: $secondary_brand;
  text-decoration: underline;
  transition: 0.5s;
  &:hover {
    color: $brand;
  }
}
.m__t__80 {
  margin-top: 154px;
}
.flex__wrapp {
  display: flex;
  //align-items: center;
  flex-wrap: wrap;
  //margin-top: 15px;
  justify-content: center;
  width: 100%;
  & .form__box__col {
    margin: 0 0 20px 0;
    & h2,
    h3 {
      margin-top: 0;
    }
  }
  & h1 {
    padding-top: 0;
  }
}
.js__display__n {
  display: none;
}

.burger {
  display: none;
  width: 40px;
  height: 30px;
  cursor: pointer;
  //border: 1px solid black;
  position: relative;
  z-index: 200;
  margin-bottom: 10px;
}
.burger__line {
  display: block;
  width: 100%;
  height: 4px;
  background-color: $brand;
  position: absolute;
  left: 0;
  transition: 0.3s all linear;
  border-radius: 20px;
}
.burger__line__first {
  top: 0;
}
.burger__line__second,
.burger__line__third {
  top: 50%;
  transform: translateY(-50%);
}

.burger__line__fourth {
  bottom: 0;
}
.burger__active {
  & .burger__line__first,
  .burger__line__fourth {
    opacity: 0;
  }
  & .burger__line__second {
    transform: translateY(-50%) rotate(45deg);
  }
  & .burger__line__third {
    transform: translateY(-50%) rotate(-45deg);
  }
}
.burger__menu__wrapp {
  display: none;
  transition: 0.2s;
  position: absolute;
  left: 0;
  top: -10px;
  transform: translateX(-200%);
  z-index: 199;
  background-color: $white;
  height: 100vh;
  border-right: 2px solid $brand;
  box-shadow: 0 6px 16px rgba(253, 180, 24, 0.3);
  & img {
    margin-top: 10px;
    margin-left: 20px;
  }
  & .link__wrapp a {
    margin-top: 9px;
  }
}
.burger__menu__wrapp.active {
  display: block;
  transition: 0.5s;
  transform: translateX(0%);
}
.link__wrapp {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 20px;
  font-size: 16px;
  margin-top: 40px;
}
.btn__box {
  display: flex;
  align-items: center;
}
#phone__burger {
  display: none;
  text-align: right;
  & a {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
.text__wrapp a {
  width: max-content;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.robot__wrapp a {
  width: max-content;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.checkbox__row {
  max-width: 320px;
  & span {
    max-width: 200px;
  }
}
.function__wrapp {
  display: none;
  width: 100%;
  & a {
    margin: 0 auto;
  }
  & .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.form__row.flex {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  & .form__input {
    min-width: 142px;
  }
  & img {
    width: 100px;
    height: auto;
  }
}
.footer__t {
  font-size: 12px;
}
.error {
  display: block;
  border-color: $red !important;
  box-shadow: 0 0 6px 3px $red_shadow !important;
}
.err {
  display: none;
  color: $red;
  max-width: 320px;
}
.pass__ress {
  transition: 0.5s;
  color: $secondary_brand;
  font-size: 14px;
  &:hover {
    color: $brand;
  }
}
#err-all,
#err-auth {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}
.ml_20 {
  position: relative;
  margin-left: 20px;
}
.form__row.center {
  text-align: center;
}
#top__btn_cabinet {
  display: none;
}
.tarif__table {
  background-color: $light_yellow;
  border-collapse: collapse;
  & tr {
    border: 1px solid $brand;
  }
  & td {
    padding: 10px;
  }
}
.tarif__table__dop {
  background-color: $light_yellow;
  border-collapse: collapse;
  & tr {
    border: 1px solid $brand;
    & td:first-child {
      width: 50px;
    }
  }
  & td {
    padding: 11px;
  }
}
.page__title {
  font-size: 24px;
  text-align: center;
  line-height: 32px;
  margin-top: -8px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  background-color: white !important;
  box-shadow: 0 0 0px 1000px #fff inset;
  position: relative;
  z-index: 8;
}
#btn__box__m {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & a {
    margin-top: 15px;
    margin-left: 15px;
  }
}
#b__phone {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  margin-left: 15px;
  & svg {
    padding-left: 0;
  }
  & a {
    margin-left: 0;
    margin-top: 15px;
    font-size: 16px;
  }
  & span {
    margin-top: 15px;
  }
}
.news__page {
  margin-bottom: 40px;
  & a {
    // display: block;
    margin-top: 15px;
    width: max-content;
  }
  & div {
    margin-top: 15px;
    &:last-child {
      margin-bottom: 15px;
    }
  }
}
.green {
  background-color: $success_d;
  padding: 10px 12px;
  text-align: center;
  border-radius: 3px;
}
.red {
  background-color: $red;
  padding: 10px 12px;
  text-align: center;
  border-radius: 3px;
  color: $white;
}
.none {
  display: none;
}
.green__text {
  color: $success_d;
}
.page-item.active .page-link {
  background-color: $gray;
}
.err__none {
  display: none;
}
.captcha__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.svg__btn {
  transition: 0.4s;
  fill: $dark;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    fill: $gray;
  }
}
.captcha {
  width: 150px;
  margin-right: 5px;
}
.form__cont {
  & .form__row {
    width: 100%;
  }
  & input {
    width: 100%;
    // width: 360px;
  }
  & textarea {
    width: 100%;
    // width: 360px;
  }
}

#err-contact__captcha,
#err-contact__captcha2 {
  max-width: 360px;
  width: 100%;
  margin-top: -12px;
}
#mail__ja {
  font-weight: 700;
}
.flex__btn {
  display: flex;
  align-items: center;
}
//-----

#loader {
  display: none;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.news__link {
  transition: 0.5s;
  color: #dbb321;
  text-decoration: underline !important;
  &:hover {
    color: $secondary_brand;
  }
}
.page__wrapp.color {
  background-color: $light_yellow;
}
.loader-21 {
  width: $size;
  height: $size;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 10px solid;
  border-color: rgba($lite, 0.15) rgba($lite, 0.25) rgba($lite, 0.35)
    rgba($lite, 0.5);
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.checkbox__box {
  width: 100%;
  display: flex;
  align-items: center;
}
.text__checkbox {
  width: 100%;
}
.form__registr,
.login {
  margin-top: 0;
  margin-bottom: 10px;
  max-width: 450px;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.5);

  & input {
    width: 100%;
  }
  & .checkbox__container {
    width: 100%;
    margin: 0;
    & input {
      transform: scale(1.3);
      width: max-content;
    }
    & span {
      margin-left: 15px;
    }
  }
}
.login {
  text-align: center;
  align-items: center;
}
.news__title {
  font-size: 24px;
}
.lang__abbreviation {
  display: none;
  margin-left: 7px;
}
.link__underline__none {
  transition: 0.5s;
  color: #dbb321;
  &:hover {
    color: $secondary_brand;
  }
}
.news__title {
  font-size: 24px;
  text-align: center;
}
.news_sidebar_wrapp {
  position: relative;
  min-width: 200px;
}
.news_sidebar {
  margin-top: 5px;
  width: max-content;
  height: max-content;
  margin-right: 24px;
  box-shadow: 0px 15px 22px rgba(41, 45, 50, 0.4);
  padding: 20px;
  border-radius: 8px;
  position: fixed;
}
.news_sidebar_header {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
}
.cat_link {
  width: 100%;
  margin-top: 16px;
  & a {
    width: 100%;
    transition: 0.3s;
    color: $secondary_brand;
    text-decoration: none;
    display: block;
    &:hover {
      color: $dark;
    }
  }
}
.cat_link.active {
  font-weight: 700;
  color: $dark;
}
.news_sidebar_mobile {
  margin-top: 10px;
  margin-right: 10px;
  display: none;
  justify-content: center;
  flex-wrap: wrap;
}
.cat_link_mobile {
  width: max-content;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  & a {
    transition: 0.3s;
    padding: 5px 10px;
    color: $secondary_brand;
    text-decoration: none;
    &:hover {
      color: $dark;
    }
  }
}
.cat_link_mobile.active {
  font-weight: 700;
  color: $dark;
}

.ml__10 {
  margin-left: 10px;
}
.mr__10 {
  margin-right: 10px;
}
.mb__10 {
  margin-bottom: 10px;
}
.news_container {
  display: flex;
}
