@media (max-width: 879px) {
    .flex__wrapp {
        justify-content: left;
    }
}
@media (max-width: 1733px) {
    .clients__app {
        bottom: 0;
        left: 75px;
        & video {
            width: 11vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
    .dispetcherskaya {
        position: absolute;
        bottom: 0;
        left: 180px;
        & video {
            width: 43vw;
        }
        &:hover {
            left: 150px;
            & video {
                width: 770px;
                left: 150px;
            }
        }
    }
    .driver__app {
        & video {
            width: 11vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
}
@media (max-width: 1553px) {
    .clients__app {
        bottom: 0;
        left: 75px;
        & video {
            width: 12vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
    .dispetcherskaya {
        position: absolute;
        bottom: 0;
        left: 160px;
        & video {
            width: 46vw;
        }
        &:hover {
            left: 70px;
            & video {
                width: 770px;
                left: 150px;
            }
        }
    }
    .driver__app {
        & video {
            width: 11vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
}
@media (max-width: 1363px) {
    .content__container {
        max-width: 1260px;
        padding: 0 25px;
    }
    .logo img {
        width: 280px;
    }
    .text__wrapp {
        max-width: 450px;
        margin: 0;
        & .text {
            width: 100%;
            text-align: centr;
            padding: 0;
        }
    }
    .clients__app {
        bottom: 0;
        left: 75px;
        & video {
            width: 13vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
    .dispetcherskaya {
        position: absolute;
        bottom: 0;
        left: 125px;
        & video {
            width: 48vw;
            left: 70px;
        }
        &:hover {
            left: 60px;
            & video {
                width: 770px;
                left: 150px;
            }
        }
    }
    .driver__app {
        & video {
            width: 12vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
}
@media (max-width: 1175px) {
    .text__wrapp {
        margin-bottom: 30px;
    }
    .nav__container {
        align-items: flex-start;
    }
    .text {
        text-align: justify;
    }
    .advantages__container {
        justify-content: center;
    }
    .slide {
        flex-direction: column;
    }
    .slide__img {
        margin: 0 15px;
    }
    .news__box {
        flex-direction: column;
    }
    .section__img {
        display: flex;
        justify-content: center;
    }
    .news {
        min-height: auto;
        margin-top: 15px;
    }
    .burger {
        display: block;
        position: fixed;
        top: 73px;
        margin-left: 10px;
    }
    #nav {
        display: none;
    }
    .nav__box {
        position: absolute;
        display: none;
    }
    #phone__burger {
        display: block;
    }
    .btn__box {
        margin-bottom: 10px;
    }
    .content__container {
        max-width: 1024px;
        padding: 0 16px;
    }

    .main__section__container {
        flex-direction: column;
        margin-top: 160px;
    }
    .text__wrapp {
        min-width: 100%;
        text-align: center;
        padding: 0 20px;
        margin-top: 10px;
    }

    .section__img img {
        width: 80%;
    }
    .section__container ul {
        font-size: 14px;
    }
    .section__box {
        flex-direction: column;
    }
    .section__text {
        text-align: center;
        & li {
            text-align: left;
        }
    }
    .section__box.flex {
        & span {
            text-align: left;
        }
    }
    .text__wrapp {
        max-width: 440px;
    }
    .clients__app {
        bottom: 0;
        left: 150px;
        & video {
            width: 18vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
    .dispetcherskaya {
        left: 270px;
        & video {
            width: 66vw;
            right: 0 !important;
        }
        &:hover {
            left: 200px;
            & video {
                width: 770px;
                left: 150px;
            }
        }
    }
    .driver__app {
        & video {
            width: 16vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
    .section__container ul {
        margin-left: 0px;
    }
    .form__input {
        width: 250px;
    }
}

@media (max-width: 859px) {
    .news_sidebar_wrapp {
        display: none;
    }
    .news_sidebar_mobile {
        display: flex;
    }
    .content__container.center {
        & .price__container {
            //display: flex;
            flex-direction: column;
        }
        & .box__price.rel {
            margin-top: 15px;
        }
        & .box__price {
            max-width: 90%;
        }
        & a {
            display: flex;
            width: max-content;
        }
        & .main__btn {
            margin-right: 0;
        }
    }
    .box__price.rel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .clients__app {
        bottom: 0;
        left: 120px;
        & video {
            width: 18vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
    .dispetcherskaya {
        left: 250px;
        & video {
            width: 66vw;
            right: 0 !important;
        }
        &:hover {
            left: 40px;
            & video {
                width: 750px;
                left: 150px;
            }
        }
    }
    .driver__app {
        & video {
            width: 16vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
    .main__video__wrapp {
        min-height: 360px;
    }
}
@media (max-width: 725px) {
    .login {
        //margin-top: 25px;
        max-width: 252px;
    }
    .form__registr {
        max-width: 725px;

        & #reg {
            padding: 0 8px;
            width: inherit;
        }
    }
    .page__title {
        font-size: 22px;
    }
    .form__box__col {
        width: inherit;
    }
    .form__input {
        width: 350px;
    }
    .form__row.flex {
        width: inherit;
    }

    .logo {
        & img {
            width: 200px;
        }
    }
    .btn__box {
        position: fixed;
        padding: 0;
        margin: 0;
        right: 0;
    }
    .phone {
        font-size: 14px;
        & a {
            font-size: 14px;
        }
    }
    .nav__fix__box {
        height: 125px;
    }
    .secondary__btn {
        font-size: 14px;
        padding: 8px 20px;
    }
    .primary__btn {
        font-size: 14px;
        padding: 8px 20px;
    }
    .dropdown__btn main {
        font-size: 14px;
    }
    .lang__name {
        display: none;
    }
    .lang__abbreviation {
        display: block;
    }
    .dropdown__menu {
        left: -19px;
    }
    .burger {
        position: fixed;
        top: 66px;
    }
    .clients__app {
        bottom: 0;
        left: 50px;
        & video {
            width: 21vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
    .dispetcherskaya {
        left: 150px;
        /* right: 0 !important; */
        & video {
            width: 70vw;
            //left: 70px;
            right: 0 !important;
        }
        &:hover {
            left: 20px;
            & video {
                width: 710px;
                left: 150px;
            }
        }
    }
    .driver__app {
        & video {
            width: 18vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
}
@media (max-width: 650px) {
    .form__registr {
        margin: 10px auto;
    }
    .slider {
        display: none;
    }
    .function__wrapp {
        display: block;
    }
    .clients__app {
        bottom: 0;
        left: 40px;
        & video {
            width: 20vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
    .dispetcherskaya {
        left: 100px;
        /* right: 0 !important; */
        & video {
            width: 69vw;
            //left: 70px;
            right: 0 !important;
        }
        &:hover {
            left: 20px;
            & video {
                width: 710px;
                left: 150px;
            }
        }
    }
    .driver__app {
        & video {
            width: 18vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
    .main__video__wrapp {
        min-height: 280px;
    }
}
@media (max-width: 583px) {
    .dropdown__menu {
        padding: 5px;
    }
    .form__registr {
        margin-top: 25px;
    }
    .login {
        margin-top: 25px;
        max-width: 252px;
    }
    .form__input {
        width: 310px;
    }
    .content__container {
        margin-top: 5px;
    }
    .nav__fix__box {
        height: 64px;
    }
    #phone__burger {
        display: none;
    }
    .burger {
        position: relative;
        top: 6px;
    }
    .nav__btn__container button {
        margin-right: 0;
    }
    .logo {
        & img {
            margin-top: 5px;
        }
    }
    .b_logo {
        margin-left: 120px;
        margin-right: 35px;
    }
    .link__wrapp {
        margin-top: 0;
    }
    .m__t__80 {
        margin-top: 68px;
    }
    .content__container.m__t__80 {
        margin-top: 80px;
    }
    .main__section__container {
        margin-top: 20px;
    }
    .nav__btn__container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .nav__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .btn__box {
        position: relative;
    }
    .btn__none {
        display: none;
    }
    .support__box {
        flex-direction: column;
    }
    .support__text__box {
        margin-top: 20px;
    }
    .form__box {
        flex-direction: column;
    }
    .burger__menu__wrapp img {
        margin-top: 18px !important;
    }
}
@media (max-width: 455px) {
    .captcha__input {
        width: 200px;
    }
    .form__row.flex {
        & img {
            width: 85px;
            height: auto;
        }
    }
    .form__cont {
        & input.captcha {
            width: 170px !important;
        }

        & .lable {
            font-size: 12px;
        }
        & .form__row.flex {
            //width: 320px;
            justify-content: space-between;
        }
    }
    .m__none {
        display: none;
    }
    .logo img {
        width: 190px;
    }
    .phone {
        font-size: 10px;
        & a {
            font-size: 14px;
        }
    }
    .clients__app {
        bottom: 0;
        left: 40px;
        & video {
            width: 32vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
    .dispetcherskaya {
        left: 90px;
        /* right: 0 !important; */
        & video {
            width: 118vw;
            //left: 70px;
            right: 0 !important;
        }
        &:hover {
            left: -40px;
            & video {
                width: 610px;
                left: 150px;
            }
        }
    }
    .driver__app {
        & video {
            width: 30vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
}
@media (max-width: 412px) {
    .clients__app {
        bottom: 0;
        left: 40px;
        & video {
            width: 32vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
    .dispetcherskaya {
        left: 90px;
        /* right: 0 !important; */
        & video {
            width: 118vw;
            //left: 70px;
            right: 0 !important;
        }
        &:hover {
            left: -48px;
            & video {
                width: 610px;
                left: 150px;
            }
        }
    }
    .driver__app {
        & video {
            width: 30vw;
        }
        &:hover {
            & video {
                width: 220px;
            }
        }
    }
    .main__video__wrapp {
        min-height: 315px;
    }
    .text__wrapp h1 {
        font-size: 20px;
    }
    .text__wrapp .text {
        font-size: 14px;
    }
}
