.not_found_container{
  margin-top: 150px;

}
// .not_found_block{

// }
.not_found_code{
  font-size: 100px;
  text-align: center;
  font-weight: 700;
}
.not_found_text{
  font-size: 28px;
  text-align: center;
}